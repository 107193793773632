import React from "react";
import styled from "styled-components";

const SearchInput = ({ id, onInputChange, title, placeholder, className }) => {
  return (
    <div className={className}>
      {title && (
        <div className="search-input__title">
          <h2>{title}</h2>
        </div>
      )}
      <input
        className="search-input__input-field"
        type="text"
        id={`search-input__${id}-filter`}
        placeholder={placeholder}
        onChange={onInputChange}
        maxLength={150}
      />
    </div>
  );
};

const StyledSearchInput = styled(SearchInput)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 50%;
  padding-bottom: 2em;

  h2 {
    font-size: 1.3em;
  }

  .search-input__title {
    text-align: center;
  }

  .search-input__input-field {
    height: 1.5em;
    font-size: 1.5em;
    padding: 0.5em;
  }

  .search-input__input-field:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 80%;

    h2 {
      font-size: 1.2em;
    }

    .search-input__input-field {
      height: 1.2em;
      font-size: 1em;
      padding: 0.5em;
    }

    .search-input__input-field:focus {
      outline: none;
    }
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`;

export default StyledSearchInput;

import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import SEO from "react-seo-component";

import Layout from "../components/Layout";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import PostList from "../components/PostList";
import {
  EMPTY_QUERY,
  EMPTY_POSTS_LIST,
  isQueryEmpty,
  isPostListEmpty,
  filterByTitleTagsAndBody,
} from "../shared/utils/postHelpers";
import StyledSearchInput from "../shared/components/SearchInput";
import StyledTitle from "../shared/components/Title";
import StyledPostCounter from "../shared/components/PostCounter";
import RectangleButton from "../shared/components/RectangleButton";

export default ({ data }) => {
  const {
    description,
    title,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
    image,
  } = useSiteMetadata();
  const logs = data.allMdx.nodes;
  const [state, setState] = React.useState({
    filteredPosts: [],
    query: EMPTY_QUERY,
  });

  const handleInputChange = (event) => {
    const query = event.target.value;
    const posts = logs || EMPTY_POSTS_LIST;
    let filteredPosts;

    if (isPostListEmpty(posts)) {
      filteredPosts = EMPTY_POSTS_LIST;
    } else {
      filteredPosts = filterByTitleTagsAndBody(posts, query);
    }

    setState({
      query,
      filteredPosts,
    });
  };

  const { filteredPosts, query } = state;
  const hasSearchResults = filteredPosts && !isQueryEmpty(query);
  const displayPosts = hasSearchResults ? filteredPosts : logs;

  return (
    <React.Fragment>
      <Layout>
        <SEO
          title={title}
          description={description}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          twitterUsername={twitterUsername}
          authorName={authorName}
          author={authorName}
          defaultImage={image}
        />
        <StyledTitle label={"Search Logs"} />
        <Btn>
          <RectangleButton iconLabel={"View All"} link={"/logs"} />
        </Btn>
        <StyledSearchInput
          id={"logs__search-filter"}
          onInputChange={handleInputChange}
          placeholder={"Search logs..."}
        />
        {hasSearchResults && !isQueryEmpty(query) && (
          <>
            <StyledPostCounter
              numberOfPosts={displayPosts.length}
              query={query}
            />
            <PostList posts={displayPosts}></PostList>
          </>
        )}
      </Layout>
    </React.Fragment>
  );
};

const Btn = styled.div`
  display: flex;
  justify-content: center;
`;

export const query = graphql`
  query SITE_LOGS_SEARCH_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true }, tags: { in: "logs" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          date
          icon
          tags
        }
        fields {
          slug
        }
        body
      }
    }
  }
`;

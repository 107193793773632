export const EMPTY_QUERY = "";
export const EMPTY_POSTS_LIST = [];

export const isQueryEmpty = query => query === EMPTY_QUERY;

export const isPostListEmpty = posts => {
  return posts.length < 1;
};

export const filterByTitleTagsAndBody = (posts, query) => {
  return posts.filter(post => {
    const { title, tags } = post.frontmatter;
    const tagsList = tags.map(tag => tag.toLowerCase());
    const { body } = post;

    return (
      body.toLowerCase().includes(query.toLowerCase()) ||
      title.toLowerCase().includes(query.toLowerCase()) ||
      tagsList.includes(query.toLowerCase())
    );
  });
};

export const generateLogPostsList = posts => {
  return filterPostsContainingTerm(posts, "logs");
};

export const generateBlogPostsList = posts => {
  return filterPostsNotContainingTerm(posts, "logs");
};

export const generateSlicedLogList = (posts, maxNumber) => {
  return slicePostsList(generateLogPostsList(posts), maxNumber);
};

export const generateSlicedBlogList = (posts, maxNumber) => {
  return slicePostsList(generateBlogPostsList(posts), maxNumber);
};

export const filterPostsContainingTerm = (posts, term) => {
  return posts.filter(post => post.frontmatter.tags.includes(term));
};

export const getRandomPosts = (posts, numberOfPosts) => {
  const shuffledPosts = posts.sort(() => 0.5 - Math.random());

  return shuffledPosts.length > numberOfPosts
    ? shuffledPosts.slice(0, numberOfPosts)
    : shuffledPosts.slice(0, shuffledPosts.length);
};

const filterPostsNotContainingTerm = (posts, term) => {
  return posts.filter(post => !post.frontmatter.tags.includes(term));
};

const slicePostsList = (posts, maxNumber) => {
  const postNumber = maxNumber > posts.length ? posts.length : maxNumber;
  return maxNumber === null ? posts : posts.slice(0, postNumber);
};

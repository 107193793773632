import React from "react";
import styled from "styled-components";

const PostCounter = ({ numberOfPosts, query, className }) => {
  const postOrPosts = numberOfPosts > 1 ? "posts" : "post";
  const postsFound = numberOfPosts > 0;

  return (
    <React.Fragment>
      <div className={className}>
        {postsFound && (
          <p
            className={"post-counter__text-posts-found"}
          >{`${numberOfPosts} ${postOrPosts} found for "${query}":`}</p>
        )}

        {!postsFound && (
          <p
            className={"post-counter__text-no-posts-found"}
          >{`No posts found for "${query}"`}</p>
        )}
      </div>
    </React.Fragment>
  );
};

const StyledPostCounter = styled(PostCounter)`
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  width: 50%;
  margin: auto;
  border-radius: 10px;
  margin-top: 0.5em;
  word-wrap: break-word;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`;

export default StyledPostCounter;
